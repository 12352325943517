import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="32.927" height="24.2" viewBox="0 0 32.927 24.2" {...props}>
      <g transform="translate(0.1 0.1)">
        <g transform="translate(18.545 9.818)">
          <g transform="translate(0 0)">
            <path
              d="M297.225,221.867a7.091,7.091,0,1,0,7.091,7.091A7.1,7.1,0,0,0,297.225,221.867Zm0,13.091a6,6,0,1,1,6-6A6.007,6.007,0,0,1,297.225,234.958Z"
              transform="translate(-290.134 -221.867)"
              fill="#404041"
              stroke="#404041"
              strokeWidth="0.2"
            />
          </g>
        </g>
        <g transform="translate(22.364 13.636)">
          <path
            d="M353.14,281.6a3.273,3.273,0,1,0,3.273,3.273A3.276,3.276,0,0,0,353.14,281.6Zm0,5.454a2.182,2.182,0,1,1,2.182-2.182A2.184,2.184,0,0,1,353.14,287.055Z"
            transform="translate(-349.867 -281.6)"
            fill="#404041"
            stroke="#404041"
            strokeWidth="0.2"
          />
        </g>
        <g transform="translate(3.818 15.818)">
          <path
            d="M61.916,315.733a2.182,2.182,0,1,0,2.182,2.182A2.184,2.184,0,0,0,61.916,315.733Zm0,3.273a1.091,1.091,0,1,1,1.091-1.091A1.092,1.092,0,0,1,61.916,319.006Z"
            transform="translate(-59.734 -315.733)"
            fill="#404041"
            stroke="#404041"
            strokeWidth="0.2"
          />
        </g>
        <path
          d="M29.455,74.267H27.393l-.545-2.727h.425a.545.545,0,0,0,.545-.545V68.812a.545.545,0,0,0-.545-.545H15.818a2.73,2.73,0,0,0-2.727,2.727.545.545,0,0,0,.545.545h1.036L14.2,76.453c-.008,0-.015,0-.023,0h-6c-2.074,0-3.647,2.074-3.8,4.047A6,6,0,1,0,11.9,87.358h5.167a.545.545,0,0,0,.536-.647,8.183,8.183,0,0,1,14.175-6.947.546.546,0,0,0,.955-.361V77.54A3.276,3.276,0,0,0,29.455,74.267ZM6,91.176a4.909,4.909,0,1,1,4.909-4.909A4.915,4.915,0,0,1,6,91.176Zm10.427-4.909H12a6.007,6.007,0,0,0-6-6c-.167,0-.333.011-.5.025a3.793,3.793,0,0,1,.4-1.136.523.523,0,0,0,.1.02H7.091a.545.545,0,1,0,0-1.091H6.776a2.235,2.235,0,0,1,1.406-.545H14.1L13.639,82.4a.546.546,0,0,0,.543.6h2.452a9.231,9.231,0,0,0-.27,2.182A9.354,9.354,0,0,0,16.427,86.267ZM25.636,75.9a9.28,9.28,0,0,0-8.669,6.011.492.492,0,0,0-.058-.011H14.782l.987-10.364h9.965l.884,4.421A9.332,9.332,0,0,0,25.636,75.9Zm1.091-5.455H14.275a1.639,1.639,0,0,1,1.543-1.091H26.727v1.091Zm4.909,7.658a9.278,9.278,0,0,0-3.865-1.945l-.16-.8h1.844a2.184,2.184,0,0,1,2.182,2.182v.567Z"
          transform="translate(0 -68.267)"
          fill="#404041"
          stroke="#404041"
          strokeWidth="0.2"
        />
      </g>
    </Svg>
  )
}

export default Icon
