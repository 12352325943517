import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18.938" height="24" viewBox="0 0 18.938 24" {...props}>
      <g transform="translate(-54)">
        <g transform="translate(54)">
          <path
            d="M72.469,12.855H70.326a5.039,5.039,0,0,0-5.033,5.033v1.948l-1.356,1.356V10.009A5.019,5.019,0,0,0,68.23,3.436a.469.469,0,0,0-.89.295,4.073,4.073,0,1,1-2.659-2.61.469.469,0,1,0,.279-.9A5.016,5.016,0,1,0,63,10.009V17.15l-1.356-1.356V13.846a5.039,5.039,0,0,0-5.033-5.033H54.469A.469.469,0,0,0,54,9.281v2.142a5.039,5.039,0,0,0,5.033,5.033h1.948L63,18.476v4.587H58.3a.469.469,0,0,0,0,.938H68.633a.469.469,0,0,0,0-.937h-4.7v-.545L65.956,20.5H67.9a5.039,5.039,0,0,0,5.033-5.033V13.323A.469.469,0,0,0,72.469,12.855ZM59.033,15.519a4.1,4.1,0,0,1-4.1-4.1V9.75h1.674a4.1,4.1,0,0,1,4.1,4.1v1.011l-2.07-2.07a.469.469,0,0,0-.663.663l2.07,2.07ZM72,15.466a4.1,4.1,0,0,1-4.1,4.1H66.894l2.07-2.07a.469.469,0,1,0-.663-.663l-2.07,2.07V17.887a4.1,4.1,0,0,1,4.1-4.1H72Z"
            transform="translate(-54)"
            fill="#404041"
          />
          <path
            d="M315.349,30.138a.97.97,0,1,0-.685-.284A.976.976,0,0,0,315.349,30.138Z"
            transform="translate(-302.853 -25.467)"
            fill="#404041"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
